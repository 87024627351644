import { ApolloClient, FetchResult, NormalizedCacheObject } from '@apollo/client'
import Cookies from 'universal-cookie'

import { gql } from '../../__generated__/graphql/catalog'
import {
  BuyerIdentityInput,
  CartCreateMutation,
  CartLineInput,
  CartQuery,
  CustomAttribute,
} from '../../__generated__/graphql/catalog/graphql'
import { reportClientError } from '../../app/_components/chrome/scripts/DataDogRumScript'
import { convertCookiesToAccountAuthorization } from '../../app/_config/Authentication.config'
import { cookieKeys } from '../../app/_config/Cookies.config'
import { sessionStorageKeys } from '../../app/_config/Session.config'
import { CheckoutLineItemInput } from '../../types/shopify-storefront-api'
import { CART_CREATE } from '../graphql/mutations/CART_CREATE'

import { ICheckoutClient } from './interfaces'
import { IterableKeys } from './types'

const CART = gql(`
  query cart($cartId: String!) {
    cart(cartId: $cartId) {
      id
      attributes {
        key
        value
      }
      checkoutUrl
      lines(first: 250) {
        edges {
          node {
            id
            quantity
            attributes {
              key
              value
            }
            discountAllocations {
              discountedAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
      discountDetails {
        amount
        currencyCode
        targetType
      }
    }
  }
`)
export class CartServiceApiClient implements ICheckoutClient {
  private cookies = new Cookies()
  private account = convertCookiesToAccountAuthorization({
    authCookie: this.cookies.get(cookieKeys.authToken.key) ?? undefined,
    authRefreshCookie: this.cookies.get(cookieKeys.authRefreshToken.key) ?? undefined,
    shopifyCustomerCookie: this.cookies.get(cookieKeys.shopifyCustomerToken.key) ?? undefined,
  })
  private apolloClient: ApolloClient<NormalizedCacheObject>

  constructor({ apolloClient }: { apolloClient: ApolloClient<NormalizedCacheObject> }) {
    this.apolloClient = apolloClient
  }

  private get iterableAttributionValues(): { key: string; value: string }[] {
    const iterableKeys = [
      IterableKeys.CAMPAIGN_ID,
      IterableKeys.MESSAGE_ID,
      IterableKeys.TEMPLATE_ID,
      IterableKeys.USER_ID,
      IterableKeys.SMS_CAMPAIGN_ID,
    ]
    const values: { key: string; value: string }[] = []

    iterableKeys.forEach(key => {
      const value = this.cookies.get(key) ?? ''

      // currently we map both email and sms campaigns to the same checkout attribute but sms takes precedence
      if (key === IterableKeys.SMS_CAMPAIGN_ID) {
        key = IterableKeys.CAMPAIGN_ID
      }

      values.push({ key, value })
    })

    return values
  }

  /**
   * Private helper that builds custom attributes.
   * Reads cookies and session storage to determine if a customer is logged in,
   * obtains the current location, and appends any iterable attribution values.
   */
  private getCustomAttributes(): CustomAttribute[] {
    const isLoggedIn = !!this.account
    const iterableValues = this.iterableAttributionValues
    const currentLocation =
      globalThis.document.location.protocol +
      '//' +
      globalThis.document.location.hostname +
      globalThis.document.location.pathname +
      globalThis.document.location.search
    const landingLocation = globalThis.sessionStorage?.getItem(sessionStorageKeys.landingPage)
    const staticCustomAttributes: CustomAttribute[] = [{ key: 'appSource', value: 'web' }]

    const customAttributes: CustomAttribute[] = [
      ...staticCustomAttributes,
      {
        key: 'landing_site',
        value: landingLocation ?? currentLocation,
      },
      {
        key: 'is_customer_logged_in',
        value: String(isLoggedIn),
      },
    ]
    iterableValues.forEach(item => {
      if (item.value) {
        customAttributes.push(item)
      }
    })
    return customAttributes
  }

  /**
   * Private helper that performs the CART_CREATE mutation.
   * Throws an error if no data is returned.
   */
  private async _createCheckoutFromCartService(
    items: CheckoutLineItemInput[],
    buyerIdentity?: BuyerIdentityInput,
    discountCodes?: string[],
    globalCustomAttributes?: CustomAttribute[]
  ): Promise<FetchResult<CartCreateMutation>> {
    const cartLineItems: CartLineInput[] = items.map(item => ({
      merchandiseId: atob(item.variantId).replace(/^gid:\/\/shopify\/ProductVariant\//, ''),
      quantity: item.quantity,
      attributes: (item.customAttributes ?? []).map(attr => ({
        key: attr.key,
        value: String(attr.value),
      })),
    }))

    const result = await this.apolloClient.mutate({
      mutation: CART_CREATE,
      variables: {
        input: {
          lines: cartLineItems,
          buyerIdentity: buyerIdentity,
          discountCodes: discountCodes,
          attributes: globalCustomAttributes,
        },
      },
      context: {
        retryOnFailure: true,
      },
    })

    return result
  }

  /**
   * Public method that creates a checkout via the cart service.
   *
   * It delegates to _createCheckoutFromCartService, performs error checking,
   * and appends additional query parameters (such as discount codes and FBCLID).
   */
  public async createCheckoutWithCartItems({
    items,
    regionId,
    languageGroup,
    customerShopifyToken,
    discountCodes,
    globalCustomAttributes,
  }: Parameters<ICheckoutClient['createCheckoutWithCartItems']>[0]): ReturnType<
    ICheckoutClient['createCheckoutWithCartItems']
  > {
    const customAttributes = [...this.getCustomAttributes(), ...(globalCustomAttributes || [])]
    const buyerIdentity: BuyerIdentityInput = {}
    buyerIdentity.email = this.account?.email
    if (customerShopifyToken) {
      buyerIdentity.customerAccessToken = customerShopifyToken
    }
    buyerIdentity.language = languageGroup
    buyerIdentity.countryCode = regionId

    let checkout = await this._createCheckoutFromCartService(
      items,
      buyerIdentity,
      discountCodes,
      customAttributes
    )

    // Define error context properties that should be captured across all reported checkout errors
    const sharedErrorContext = {
      scope: 'checkout',
      errors: checkout.errors,
      userErrors: checkout.data?.cartCreate.userErrors,
    }

    if (
      (checkout.errors && checkout.errors.length > 0) ||
      (checkout.data?.cartCreate.userErrors && checkout.data?.cartCreate.userErrors.length > 0)
    ) {
      reportClientError({
        error: new Error('errors returned by `cartCreate` mutation'),
        context: {
          label: 'cartCreate errors',
          ...sharedErrorContext,
        },
      })
    }

    if (!checkout.data) {
      reportClientError({
        error: new Error('No data returned from CART_CREATE mutation'),
        context: { label: 'cartCreate missing data', ...sharedErrorContext },
      })
      throw new Error('Checkout creation failed: missing data.')
    }
    let validatedLines = items
    if (checkout.data.cartCreate.userErrors && checkout.data.cartCreate.userErrors.length > 0) {
      const normalizedUserErrors = checkout.data.cartCreate.userErrors.map(e => ({
        __typename: e.__typename,
        code: e.code ?? 'UNKNOWN_ERROR',
        field: (e.field ?? []).filter((f): f is string => f !== null),
        message: e.message,
      }))

      validatedLines = this.filterInvalidCartItemsOnError(items, normalizedUserErrors)

      if (validatedLines.length) {
        checkout = await this._createCheckoutFromCartService(
          validatedLines,
          buyerIdentity,
          discountCodes,
          customAttributes
        )
        // Update `errors` and `userErrors` in shared context after retry
        sharedErrorContext.errors = checkout.errors
        sharedErrorContext.userErrors = checkout.data?.cartCreate.userErrors
      }
    }

    if (!checkout.data) {
      reportClientError({
        error: new Error('No data returned from CART_CREATE mutation'),
        context: { label: 'cartCreate missing data after retry', ...sharedErrorContext },
      })
      throw new Error('Checkout creation failed: missing data.')
    }

    const shopifyCart = checkout.data.cartCreate.cart
    if (!shopifyCart?.id) {
      reportClientError({
        error: new Error('No cart ID found in cartCreate response'),
        context: { label: 'cartCreate missing cart ID', ...sharedErrorContext },
      })
      throw new Error('Checkout creation failed: missing cart ID.')
    }

    const checkoutString = shopifyCart.checkoutUrl
    if (!checkoutString) {
      reportClientError({
        error: new Error('No checkoutUrl found in cartCreate response'),
        context: { label: 'cartCreate missing checkoutUrl', ...sharedErrorContext },
      })
      throw new Error('Checkout creation failed: missing checkoutUrl.')
    }

    const cartId = shopifyCart.id
    const checkoutUrl = new URL(checkoutString)
    const discountCode = globalThis.sessionStorage?.getItem(sessionStorageKeys.discount)
    if (discountCode) {
      checkoutUrl.searchParams.append('discount', discountCode)
    }
    if (discountCodes && discountCodes.length > 0) {
      checkoutUrl.searchParams.append('discount', discountCodes[0]!)
    }

    const facebookCookieVal = this.cookies.get(cookieKeys.facebook.key)
    if (facebookCookieVal) {
      checkoutUrl.searchParams.append('FBCLID', facebookCookieVal)
    }

    return { checkoutUrl, checkoutId: cartId }
  }

  private filterInvalidCartItemsOnError<T>(
    items: T[],
    errors: Array<{ code: string; field: string[]; message: string }>
  ): T[] {
    let validItems: T[] = []
    const invalidIndices: number[] = []
    const errorCodesToHandle: String[] = ['NOT_ENOUGH_IN_STOCK', 'PRODUCT_NOT_AVAILABLE']
    errors.forEach(err => {
      if (errorCodesToHandle.includes(err.code) || err.code.startsWith('INVALID')) {
        invalidIndices.push(Number.parseInt(err.field[2]!))
      }
    })
    items.forEach((item: T, index: number) => {
      if (!invalidIndices.includes(index)) validItems.push(item)
    })
    return validItems
  }

  public async isCheckoutCompleted({
    checkoutId,
  }: Parameters<ICheckoutClient['isCheckoutCompleted']>[0]): ReturnType<
    ICheckoutClient['isCheckoutCompleted']
  > {
    const checkout = await this.fetchCart(checkoutId)
    return checkout && !checkout.cart ? true : false
  }

  private async fetchCart(cartId: string): Promise<CartQuery | null> {
    const { data, error } = await this.apolloClient.query({
      query: CART,
      variables: {
        cartId: cartId,
      },
      fetchPolicy: 'network-only',
    })
    if (error) throw error
    return data
  }
}
